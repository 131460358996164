
import TheCarAnimation from "@/components/SVG/TheCarAnimation.vue";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
  components: { TheCarAnimation },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isNotAuthenticated = !store.getters.user;
    const icons = ["time", "checklist", "queing", "electric-car", "planning"];

    return { t, isNotAuthenticated, icons };
  },
});
